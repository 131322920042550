import React from 'react';
import BlockPage from 'layout/components/BlockPage';
import BlockSection from 'sections/Block';
import Filter from 'components/Filter';
import Message from 'components/Message';
import { AccordionBlock } from 'blocks';
import Layout from 'components/Layout';
import FaqPageWrapper from './styles';
import HeroAd from 'components/HeroAd';
import { DFPManager } from 'react-dfp';
import { FooterAd } from 'components/FooterAd';

function mapOrder(array, order, key) {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A) > order.indexOf(B)) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

class FaqPage extends BlockPage {
  state: any;
  props: any;

  componentDidMount() {
    setTimeout(() => {
      DFPManager.load();
    }, 5000);
  }
  constructor(props) {
    super(props);

    const cats = props?.faqCategoriess?.nodes;
    const catsArray = Object.keys(cats).map((key) => {
      return cats[key].name;
    });

    const faqs = props.faqs.nodes.reduce((faqsSoFar, { title, content, faqCategoriess }) => {
      const category = faqCategoriess.nodes[0];
      if (category) {
        if (!faqsSoFar[category.name]) faqsSoFar[category.name] = {};

        faqsSoFar[category.name]['name'] = category.name;

        if (!faqsSoFar[category.name]['faqs']) faqsSoFar[category.name]['faqs'] = [];

        faqsSoFar[category.name]['faqs'].push({ title: title, content: content });
      }
      return faqsSoFar;
    }, {});
    const faqArray = Object.keys(faqs).map(function (key) {
      return faqs[key];
    });
    const orderedFaqs = mapOrder(faqArray, catsArray, 'name');

    this.state = {
      inputValue: null,
      faqs: orderedFaqs,
      faqBlocks: orderedFaqs,
    };
    this.hideAnnouncement = this.hideAnnouncement.bind(this);
    this.handleLoad = this.handleLoad.bind(this);
  }

  handleFilter(e) {
    const { value } = e.target;
    const { faqs = [] } = this.state;

    const faqBlocks = faqs.map(({ name, faqs: items }) => ({
      name,
      faqs: items.filter(({ title, content }) =>
        [name, title, content].some((text: string) => (text ? text.toLowerCase().includes(value.toLowerCase()) : false)),
      ),
    }));

    this.setState({ inputValue: value, faqBlocks });
  }

  renderChildren() {
    const { faqBlocks } = this.state;
    const { title, faqs = [], adCode, adImage } = this.props.content;

    const results = faqBlocks.reduce((acc, { faqs: items }) => (acc += items.length), 0);

    return (
      <FaqPageWrapper>
        <BlockSection isFirstSection={true} pageHasAnnouncement={this.pageHasAnnouncement()} color="lime">
          <Filter.TextInputHeader
            title={title}
            placeholder="Type here to search"
            value={this.state.inputValue}
            onChange={(e) => this.handleFilter(e)}
          />
        </BlockSection>
        <HeroAd />
        <BlockSection isFirstSection={false} pageHasAnnouncement={this.pageHasAnnouncement()}>
          <Layout.PageContainer hasSidebar={false}>
            <Layout.Content>
              <Filter.Content>
                {results ? (
                  faqBlocks.map(
                    ({ name, faqs: items }, index) => (items.length > 0) && <BlockSection key={index}><AccordionBlock key={`post-${index}`} title={name} items={items} /></BlockSection>,
                  )
                ) : (
                  <Message value={`Sorry there are no results for "${this.state.inputValue}"`} />
                )}
              </Filter.Content>
            </Layout.Content>
          </Layout.PageContainer>
        </BlockSection>
        <Layout.Content>
          <BlockSection style={{ textAlign: 'center' }}>
            <FooterAd />
          </BlockSection>
        </Layout.Content>
      </FaqPageWrapper>
    );
  }
}

export default FaqPage;
