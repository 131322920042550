import styled from 'styled-components';
import { ThemeType } from 'types';
import Content from 'components/Layout/Content';

const FaqPageWrapper = styled.div(
  ({ theme: { spacing } }: ThemeType) => `
  ${Content}{
    section {
      padding-top: ${spacing.x2};
      padding-bottom: ${spacing.x2};
    }
  }
 `,
);

export default FaqPageWrapper;
