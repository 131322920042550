import React from 'react';
import Page from '.';
import { graphql } from 'gatsby';
import { PageDataType } from 'types/Page';

interface Props {
  data: PageDataType;
}

const PageTemplate: React.StatelessComponent<Props> = ({ data }) => <Page {...data} />;

export default PageTemplate;

export const query = graphql`
query($id: String!, $language: WpLanguageCodeEnum!, $headerMenu: WpMenuLocationEnum!, $mainMenu: WpMenuLocationEnum!, $footerMenu: WpMenuLocationEnum!, $secondaryFooterMenu: WpMenuLocationEnum!) {
    headerMenu: wpMenu(locations: { eq: $headerMenu }) {
        ...Menu
      }
      mainMenu: wpMenu(locations: { eq: $mainMenu }) {
        ...Menu
      }
      footerMenu: wpMenu(locations: { eq: $footerMenu }) {
        ...Menu
      }
      secondaryFooterMenu: wpMenu(locations: { eq: $secondaryFooterMenu }) {
        ...Menu
      }
      faqs: allWpFaq(filter: {language: {code: {eq: $language}}}) {
        nodes {
          id
          faqCategoriess {
            nodes {
              id
              name
            }
          }
          title
          content
        }
      }
      faqCategoriess: allWpFaqCategories(filter: {language: {code: {eq: $language}}}) {
        nodes {
          name
          id
        }
      }
    content: wpPage(id: { eq: $id }) {
        ...Page
      }
      announcement: allWpAnnouncement(filter: {language: {code: {eq: $language}}}, limit: 1, sort: {fields: date, order: ASC}) {
        nodes {
          ...Announcement
        }
      }
  }

`;